import * as React from 'react'

function SvgLinkedIn(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="inline-flex"
      {...props}
    >
      <path
        d="M6.477 22H2.33V8.647h4.147V22zM4.4 6.826C3.075 6.826 2 5.727 2 4.402a2.401 2.401 0 014.803 0c0 1.325-1.076 2.424-2.402 2.424zM21.996 22h-4.138v-6.5c0-1.55-.031-3.536-2.156-3.536-2.156 0-2.486 1.683-2.486 3.424V22H9.074V8.647h3.977v1.822h.058c.553-1.05 1.906-2.157 3.923-2.157 4.197 0 4.968 2.764 4.968 6.353V22h-.005z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgLinkedIn
