import * as React from 'react'

function SvgArrow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="inline-flex"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.386 2H1V0h20.8v20.8h-2V3.414L1.707 21.507.293 20.093 18.386 2z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgArrow
