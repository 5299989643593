import { AppProps } from 'next/app'

import { useAnalytics } from '../lib/analytics/useAnalytics'

import '../styles/index.css'

export default function MyApp({ Component, pageProps }: AppProps) {
  useAnalytics()
  return <Component {...pageProps} />
}
