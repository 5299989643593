import * as React from 'react'

function SvgCross(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="inline-flex"
      {...props}
    >
      <path
        d="M23.318 17l9.476-9.476a2.979 2.979 0 000-4.212l-2.106-2.107a2.979 2.979 0 00-4.212 0L17 10.683 7.524 1.206a2.979 2.979 0 00-4.212 0L1.205 3.311a2.979 2.979 0 000 4.212L10.682 17l-9.477 9.476a2.979 2.979 0 000 4.213l2.107 2.105a2.979 2.979 0 004.212 0L17 23.319l9.476 9.476a2.979 2.979 0 004.212 0l2.107-2.105a2.979 2.979 0 000-4.213L23.317 17z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgCross
