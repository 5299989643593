import * as React from 'react'

function SvgInstagram(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="inline-flex"
      {...props}
    >
      <path
        d="M13.003 6.334A6.655 6.655 0 006.338 13a6.655 6.655 0 006.665 6.666A6.655 6.655 0 0019.668 13a6.655 6.655 0 00-6.665-6.666zm0 11A4.341 4.341 0 018.67 13a4.337 4.337 0 014.333-4.334A4.338 4.338 0 0117.336 13a4.341 4.341 0 01-4.333 4.334zM21.495 6.06c0 .865-.696 1.555-1.555 1.555a1.555 1.555 0 111.554-1.555zm4.414 1.578c-.099-2.082-.575-3.927-2.1-5.447-1.52-1.52-3.364-1.996-5.447-2.1-2.146-.122-8.578-.122-10.724 0C5.56.19 3.717.665 2.19 2.185.666 3.706.196 5.55.091 7.633c-.121 2.147-.121 8.581 0 10.728.099 2.082.575 3.927 2.1 5.447 1.526 1.52 3.364 1.996 5.447 2.1 2.146.122 8.578.122 10.724 0 2.083-.098 3.927-.574 5.447-2.1 1.52-1.52 1.995-3.364 2.1-5.447.122-2.147.122-8.575 0-10.722zm-2.773 13.025a4.387 4.387 0 01-2.47 2.471c-1.712.68-5.772.523-7.663.523s-5.957.15-7.662-.523a4.387 4.387 0 01-2.471-2.471c-.679-1.712-.522-5.773-.522-7.664s-.151-5.958.522-7.664a4.387 4.387 0 012.47-2.471c1.712-.68 5.772-.523 7.663-.523 1.89 0 5.957-.15 7.662.523a4.387 4.387 0 012.471 2.471c.679 1.712.522 5.773.522 7.664s.157 5.958-.522 7.664z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgInstagram
