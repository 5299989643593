import React, { ReactNode } from 'react'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

export enum Variant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  OUTLINED_LIGHT_BACKGROUND = 'outlinedLightBackground',
  OUTLINED_DARK_BACKGROUND = 'outlinedDarkBackground',
}
export enum Size {
  STANDARD = 'standard',
  LARGE = 'large',
}
export enum Icon {
  NONE = 'none',
  LEFT = 'left',
  RIGHT = 'right',
  ONLY = 'icon-only',
}
const ENABLED_VARIANT_TO_BUTTON_CLASSNAMES = {
  [Variant.PRIMARY]: cn(
    'bg-quaternary',
    'hover:bg-quaternary-60',
    'focus-visible:bg-quaternary-60'
  ),
  [Variant.SECONDARY]: cn(
    'bg-primary',
    'active:bg-primary-80',
    'hover:bg-primary-80',
    'focus:bg-primary-60'
  ),
  [Variant.TERTIARY]: cn(
    'bg-tertiary',
    'hover:bg-tertiary-60',
    'focus:bg-tertiary-60'
  ),
  [Variant.OUTLINED_LIGHT_BACKGROUND]: cn(
    'bg-transparent ring-2 ring-primary ring-inset',
    'active:bg-primary',
    'hover:bg-primary',
    'focus:bg-primary-60'
  ),
  [Variant.OUTLINED_DARK_BACKGROUND]: cn(
    'bg-transparent ring-2 ring-white ring-inset',
    'active:bg-white',
    'hover:bg-white',
    'focus:bg-primary-60'
  ),
}

const ENABLED_VARIANT_TO_BUTTON_CONTENT_CLASSNAMES = {
  [Variant.PRIMARY]: cn('text-primary-text', 'group-hover:text-current'),
  [Variant.SECONDARY]: cn('text-white', 'focus:text-white'),
  [Variant.TERTIARY]: cn(
    'group-hover:text-primary-60',
    'text-primary',
    'focus:text-primary'
  ),
  [Variant.OUTLINED_LIGHT_BACKGROUND]: cn(
    'text-primary-text',
    'group-hover:text-white',
    'active:text-white',
    'focus:text-white'
  ),
  [Variant.OUTLINED_DARK_BACKGROUND]: cn(
    'text-white',
    'active:text-primary-text',
    'group-hover:text-primary-text',
    'focus:text-white'
  ),
}

const DISABLED_VARIANT_TO_BUTTON_CONTENT_CLASSNAMES = {
  [Variant.PRIMARY]: 'text-primary-80',
  [Variant.SECONDARY]: 'text-white',
  [Variant.TERTIARY]: 'text-primary-80',
  [Variant.OUTLINED_LIGHT_BACKGROUND]: 'text-primary-60',
  [Variant.OUTLINED_DARK_BACKGROUND]: 'text-primary-60',
}

const DISABLED_VARIANT_TO_CLASSNAMES = {
  [Variant.PRIMARY]: 'bg-quaternary-60',
  [Variant.SECONDARY]: 'bg-secondary-60',
  [Variant.TERTIARY]: 'bg-tertiary-60',
  [Variant.OUTLINED_LIGHT_BACKGROUND]:
    'bg-transparent ring-inset ring-2 ring-primary-60',
  [Variant.OUTLINED_DARK_BACKGROUND]:
    'bg-transparent ring-inset ring-2 ring-primary-40',
}

function buttonStateToClassNames(
  size: Size,
  variant: Variant,
  icon: Icon,
  disabled: boolean
) {
  return cn(
    disabled
      ? DISABLED_VARIANT_TO_CLASSNAMES[variant]
      : ENABLED_VARIANT_TO_BUTTON_CLASSNAMES[variant],
    disabled
      ? 'cursor-not-allowed focus:outline-none'
      : 'focus:ring-3 focus:outline-none focus:ring-focus active:shadow-inner focus:ring-no-inset',
    icon === Icon.ONLY ? 'text-3xl' : '',
    size === Size.LARGE ? 'py-2 px-2  text-3xl' : 'py-2 px-2',
    'inline-flex items-center font-bold rounded'
  )
}

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: Variant
  size?: Size
  icon?: Icon
  translationKey?: string
  label?: string
  children?: ReactNode
  disabled?: boolean
  buttonLabelClassname?: string
}

export function Button({
  variant = Variant.PRIMARY,
  size = Size.STANDARD,
  icon = Icon.NONE,
  translationKey = '',
  label = '',
  children,
  disabled = false,
  buttonLabelClassname,
  ...props
}: ButtonProps) {
  const { t: translate } = useTranslation()
  const buttonLabel = (
    <span
      className={cn(
        'ml-2 mr-2',
        disabled
          ? DISABLED_VARIANT_TO_BUTTON_CONTENT_CLASSNAMES[variant]
          : ENABLED_VARIANT_TO_BUTTON_CONTENT_CLASSNAMES[variant],
        buttonLabelClassname
      )}
    >
      {!!translationKey ? translate(translationKey) : label}
    </span>
  )
  const buttonIcon = (
    <span
      className={cn(
        'inline-flex',
        disabled
          ? DISABLED_VARIANT_TO_BUTTON_CONTENT_CLASSNAMES[variant]
          : ENABLED_VARIANT_TO_BUTTON_CONTENT_CLASSNAMES[variant],
        {
          'text-3xl': size === Size.LARGE,
          'text-xl': size === Size.STANDARD,
          'ml-1 mr-1': icon !== Icon.ONLY,
        }
      )}
    >
      {children}
    </span>
  )
  return (
    <button
      {...props}
      disabled={disabled}
      className={cn(
        'group',
        buttonStateToClassNames(size, variant, icon, disabled),
        props.className
      )}
    >
      {icon === Icon.LEFT ? buttonIcon : ''}
      {icon !== Icon.ONLY ? buttonLabel : buttonIcon}
      {icon === Icon.RIGHT ? buttonIcon : ''}
    </button>
  )
}
