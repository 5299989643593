import * as React from 'react'

function SvgMenu(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 26 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="inline-flex"
      {...props}
    >
      <path
        d="M.929 4.04H25.07c.513 0 .929-.401.929-.897V.898C26 .402 25.584 0 25.071 0H.93C.416 0 0 .402 0 .898v2.245c0 .496.416.898.929.898zm0 8.98H25.07c.513 0 .929-.402.929-.898V9.878c0-.496-.416-.898-.929-.898H.93c-.513 0-.929.402-.929.898v2.244c0 .496.416.898.929.898zm0 8.98H25.07c.513 0 .929-.402.929-.898v-2.245c0-.496-.416-.898-.929-.898H.93c-.513 0-.929.402-.929.898v2.245c0 .496.416.898.929.898z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgMenu
