import { useEffect, useState } from 'react'
import { hotjar } from 'react-hotjar'
import Head from 'next/head'
import { useRouter } from 'next/router'

import { Link, LinkVariant } from './Link'
import { Button, Variant } from './Button'

import { useForceUpdate } from '../hooks/useForceUpdate'

import config from '../config'

/**
 * @returns `true` if cookies have been consented to, `false` if cookies have been declined, or `undefined` if not yet answered.
 */
export function getCookieConsent(): boolean | undefined {
  if (typeof localStorage !== 'undefined') {
    const cookieConsent = localStorage.getItem('cookieconsent')
    if (!!cookieConsent) {
      return cookieConsent === 'true'
    }
  }
  return undefined
}

export function Cookies() {
  const hotjarId = config.analytics.hotjar.id

  // store and update consent state
  const [consent, setConsentState] = useState<boolean | undefined>(false)
  useEffect(() => setConsentState(getCookieConsent()))
  const forceUpdate = useForceUpdate()
  const router = useRouter()

  /**
   * Sets cookie consent answer in localStorage so it persists
   * @param ans `true` if cookies consented, `false` if declined
   */
  function setCookieConsent(ans: boolean) {
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('cookieconsent', ans.toString())
    }
    // force component to re-render, which closes the consent banner
    forceUpdate()
  }

  /**
   * initialize Hotjar if user consented to cookies
   */
  useEffect(() => {
    if (consent) {
      if (config.analytics.enabled && !!hotjarId) {
        hotjar.initialize(parseInt(hotjarId), 6)
      }
    }
  }, [consent])
  return (
    <>
      {consent && (
        /**
         * Append analytics scripts to page head
         */
        <Head>
          {/* Google Analytics */}
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${config.analytics.gtag.id}`}
          />
          <script
            dangerouslySetInnerHTML={{
              __html: `window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${config.analytics.gtag.id}', {
              page: window.location.pathname
          });`,
            }}
          />

          {/* Facebook Pixel */}
          <script
            dangerouslySetInnerHTML={{
              __html: `
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', ${config.analytics.fbPixel.id});
              `,
            }}
          />
          <noscript>
            <img
              height="1"
              width="1"
              style={{ display: 'none' }}
              src={`https://www.facebook.com/tr?id=${config.analytics.fbPixel.id}&ev=PageView&noscript=1`}
            />
          </noscript>
        </Head>
      )}

      {/**
       * If not yet answered consent banner, grey-out the entire page.
       * Do not grey-out the privacy-policy page.
       */}
      {consent === undefined && router.pathname !== '/privacy-policy' && (
        <div className="bg-black fixed h-full left-0 opacity-70 top-0 w-full z-20" />
      )}

      {/**
       * If not yet answered consent banner, show it.
       */}
      {consent === undefined && (
        <div className="bg-white bottom-0 fixed items-center left-0 z-20 lg:bottom-8 lg:left-8 lg:max-w-800px">
          <div className="px-6 py-8 md:px-10 md:py-12 lg:flex lg:gap-x-20 lg:justify-between">
            <div>
              <p>
                We use cookies in order to give you the best website experience.
                They help us understand what content our visitors like to
                determine our content strategy and how our paid advertising
                performs so we don't waste money and can spend it effectively to
                fight the climate crisis. To accept, please click ALL:
              </p>
              <div className="mt-4 md:flex md:justify-between lg:mt-8">
                <div className="flex flex-col justify-center">
                  <Link
                    variant={LinkVariant.SUBTLE}
                    href="/privacy-policy"
                    translationKey="privacyStatement"
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="gap-6 mt-8 md:flex md:gap-11 md:mt-10 lg:block lg:justify-between lg:mt-0">
                <div className="all flex flex-col mb-6 w-48 md:mb-0 lg:mb-16">
                  <Button
                    onClick={() => setCookieConsent(true)}
                    className="justify-center"
                    translationKey="all"
                    variant={Variant.PRIMARY}
                    buttonLabelClassname="uppercase"
                  />
                </div>
                <div className="flex flex-col mb-6 none w-48 md:mb-0">
                  <Button
                    onClick={() => setCookieConsent(false)}
                    className="justify-center"
                    translationKey="none"
                    variant={Variant.OUTLINED_LIGHT_BACKGROUND}
                    buttonLabelClassname="uppercase"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
