export function pageview() {
  window.fbq('track', 'PageView')
}

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export function event(
  eventName: string,
  parameters: facebook.Pixel.ViewContentParameters
) {
  window.fbq('track', eventName, parameters)
}
