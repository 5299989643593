import * as React from 'react'
import NextLink, { LinkProps as NextLinkProps } from 'next/link'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { Arrow } from './icons/index'

export enum LinkVariant {
  FORWARD = 'forward',
  BACKWARD = 'backward',
  SUBTLE = 'subtle',
}

export interface LinkProps extends NextLinkProps {
  variant: LinkVariant
  translationKey?: string
  allowWrap?: boolean
  label?: string
  doNothing?: boolean
  tabIndex?: number
  newTab?: boolean
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
}

export function Link({
  variant = LinkVariant.FORWARD,
  translationKey,
  allowWrap = true,
  label = '',
  doNothing = false,
  tabIndex = 0,
  newTab = false,
  onClick = () => {},
  ...props
}: LinkProps) {
  const { t: translate } = useTranslation()
  return (
    <span>
      <NextLink {...props}>
        <a
          className={cn(
            {
              [cn(
                'px-0.5 -mx-0.5 text-primary font-bold underline hover:bg-quaternary',
                'focus:no-underline focus:ring-2 focus:ring-focus focus:rounded-sm'
              )]: variant === LinkVariant.SUBTLE,

              [cn(
                'text-primary z-0 rounded-sm px-2 items-baseline relative block'
              )]:
                variant === LinkVariant.BACKWARD ||
                variant === LinkVariant.FORWARD,
            },
            !allowWrap && 'whitespace-nowrap',
            doNothing && 'pointer-events-none'
          )}
          tabIndex={tabIndex}
          target={newTab ? '_blank' : ''}
          rel="noopener noreferrer"
          onClick={onClick}
        >
          {' '}
          <span
            className={cn({
              [cn(
                'box-decoration-clone ease-in-out highlight hover:bg-quaternary inline p-1 transition-height'
              )]:
                variant === LinkVariant.BACKWARD ||
                variant === LinkVariant.FORWARD,
            })}
          >
            {variant === LinkVariant.BACKWARD && (
              <span className="mr-1.5 pb-0.5">
                <Arrow style={{ transform: 'rotate(270deg)' }} />
              </span>
            )}

            <span
              className={cn({
                [cn('pr-2')]:
                  variant === LinkVariant.BACKWARD ||
                  variant === LinkVariant.FORWARD,
              })}
            >
              {!!translationKey ? translate(translationKey) : label}
            </span>

            {variant === LinkVariant.FORWARD && (
              <span className="inline ml-1.5 pb-0.5">
                <Arrow />
              </span>
            )}
          </span>
        </a>
      </NextLink>
    </span>
  )
}
