import * as React from 'react'

function SvgAccount(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="inline-flex"
      {...props}
    >
      <path d="M18 11.167a4 4 0 11-8 0 4 4 0 018 0z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 14.167c0 7.732-6.268 14-14 14s-14-6.268-14-14 6.268-14 14-14 14 6.268 14 14zm-14 12c6.627 0 12-5.373 12-12 0-6.628-5.373-12-12-12s-12 5.372-12 12c0 6.627 5.373 12 12 12z"
        fill="currentColor"
      />
      <path
        d="M22.5 24.667c-3 2-3.477 2.5-9 2.5s-7-1-8-3c0-.864-1.39-.701-1.185-1.5 1.11-4.313 5.025-7.5 9.685-7.5s8.575 3.187 9.685 7.5c.206.799-1.185 1.136-1.185 2z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgAccount
