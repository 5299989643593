import config from '../../config'

export const pageview = (url: URL) => {
  if (config.analytics.gtag.id) {
    window.gtag('config', config.analytics.gtag.id, {
      page_path: url,
    })
  }
}

export const event = (
  action: Gtag.EventNames,
  { event_category, event_label, value }: Gtag.EventParams
) => {
  window.gtag('event', action, {
    event_category: event_category,
    event_label: event_label,
    value: value,
  })
}
