import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { getCookieConsent } from '../../components/Cookies'
import * as googleAnalytics from './gtag'
import * as facebookPixel from './fbpixel'
import config from '../../config'

/**
 * Sets up page view tracking for analytics providers.
 */
export function useAnalytics() {
  const router = useRouter()
  const cookieConsent = getCookieConsent()

  useEffect(() => {
    if (config.analytics.enabled && cookieConsent) {
      const handleRouteChange = (url: URL) => {
        googleAnalytics.pageview(url)
        facebookPixel.pageview()
      }
      router.events.on('routeChangeComplete', handleRouteChange)
      return () => {
        router.events.off('routeChangeComplete', handleRouteChange)
      }
    }
  }, [router.events])
}
