type Environments = 'prod' | 'dev' | 'staging'

export const IS_PRODUCTION = process.env.ENVIRONMENT === 'prod'

const config = {
  siteUrl: process.env.NEXT_PUBLIC_SITE_URL || 'localhost:3000',
  environment:
    (process.env.ENVIRONMENT as Environments) || ('prod' as Environments),
  strapi: {
    // A flag purely for use while we are transitioning over to managing content via strapi - can be removed once everything is in place
    enabledContentModels: (['BlogPosts', 'Pages', 'PledgeHomePage'] as Array<
          'Pages' | 'BlogPosts' | 'PledgeHomePage'
        >),
    graphQlEndpoint:
      process.env.NEXT_PUBLIC_STRAPI_GQL_ENDPOINT ||
      'http://localhost:1337/graphql',
  },
  sentry: {
    enabled: IS_PRODUCTION,
    sentryDsn: process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN,
  },
  analytics: {
    enabled: IS_PRODUCTION,
    gtag: {
      id: process.env.NEXT_PUBLIC_GA_ID,
    },
    fbPixel: {
      id: process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID,
    },
    hotjar: {
      id: process.env.NEXT_PUBLIC_HOTJAR_ID,
    },
  },
  zapier: {
    webhookUrlContact:
      process.env.ZAPIER_WEBHOOK_URL_CONTACT ||
      'https://hooks.zapier.com/hooks/catch/4331049/bukekvy/',
    webhookUrlNewsletter:
      process.env.ZAPIER_WEBHOOK_NEWSLETTER ||
      'https://hooks.zapier.com/hooks/catch/4331049/buklzba/',
  },
  hCaptcha: {
    siteKey:
      process.env.NEXT_PUBLIC_HCAPTCHA_SITE_KEY ||
      '10000000-ffff-ffff-ffff-000000000001',
    secretKey:
      process.env.HCAPTCHA_SECRET_KEY ||
      '0x0000000000000000000000000000000000000000',
  },
}

export default config
