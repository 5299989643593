import * as React from 'react'

function SvgFacebook(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 14 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="inline-flex"
      {...props}
    >
      <path
        d="M13.083 15.75l.726-5.067h-4.54V7.394c0-1.386.634-2.737 2.667-2.737H14V.342S12.127 0 10.337 0C6.599 0 4.155 2.427 4.155 6.82v3.863H0v5.067h4.155V28H9.27V15.75h3.814z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgFacebook
